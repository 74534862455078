<template>
  <div>
    <div class="query-header">
      <div class="op">
        <a-button ghost type="primary" @click="handleClickAddBtn" icon="plus">{{ $t('tian-jia-chan-pin-ji-qun') }}</a-button>
        <a-button class="refresh" @click="handleRefresh" :loading="loading">{{ $t('shua-xin') }}</a-button>
      </div>
    </div>
    <a-table :columns="columns" :data-source="productClusterList"
             :locale="{emptyText: $t('zan-wu-shu-ju')}" :loading="loading"
             :rowKey="record => record.id"
             size="small">
      <template slot="clusterCode" slot-scope="record">
        <span :class="`dot-span ${record.status === 'ONLINE'?'online-span':'offline-span'}`"></span>
        {{ record.clusterCode }}
      </template>
      <template slot="clusterDesc" slot-scope="record">
        {{ record.clusterDesc }}
      </template>
      <template slot="productVersion" slot-scope="record">
        {{ record.productVersion }}
        <cc-iconfont :size="8" name="edit"
                     style="margin-right: 4px;margin-left: 4px;"
                     @click.native="handleEditProductVersion(record)"/>
      </template>
      <template slot="apiAddr" slot-scope="record">
        {{ record.apiAddr }}
        <cc-iconfont :size="8" name="edit"
                     style="margin-right: 4px;margin-left: 4px;"
                     @click.native="handleEditApiAddr(record)"/>
      </template>
      <template slot="actions" slot-scope="record">
        {{''}}
        <a-button size="small" type="link"
          @click.native="handleActions( 'delete', record)">
          {{ $t('shan-chu') }}
        </a-button>
      </template>
    </a-table>
    <AddProductClusterModal :visible="showAddProductModal" :product-types="productTypes"
                            :list-product-cluster="getProductList" :handle-cancel="handleCancel"></AddProductClusterModal>
    <a-modal v-model="showEditProductVersion" :title="$t('xiu-gai-ban-ben-hao')" :closable="false" :width="402" :cancelText="$t('qu-xiao')"
             :okText="$t('bao-cun')" wrapClassName="have-footer" @ok="handleUpdateProductVersion" @cancel="handleCancel">
      <a-input v-model="currentProductVersion" type="textarea"/>
    </a-modal>
    <a-modal v-model="showEditApiAddr" :title="$t('xiu-gai-api-tiao-yong-di-zhi')" :closable="false" :width="402" :cancelText="$t('qu-xiao')"
             :okText="$t('bao-cun')" wrapClassName="have-footer" @ok="handleUpdateApiAddr" @cancel="handleCancel">
      <a-input v-model="currentApiAddr" type="textarea"/>
    </a-modal>
  </div>
</template>
<script>
import {
  listProductCluster, listProductTypes, deleteProductCluster, updateProductVersion, updateApiAddr
} from '@/services/cc/api/productCluster';
import AddProductClusterModal from '@/components/modal/AddProductClusterModal';
import { Modal } from 'ant-design-vue';
import cloneDeep from 'lodash.clonedeep';
import { mapState } from 'vuex';

export default {
  name: 'ProductList',
  components: { AddProductClusterModal },
  data() {
    return {
      loading: false,
      showAddProductModal: false,
      showEditProductVersion: false,
      showEditApiAddr: false,
      selectedProductCluster: {},
      currentProductVersion: '',
      currentApiAddr: '',
      columns: [
        {
          title: this.$t('chan-pin-ji-qun-id'),
          key: 'clusterCode',
          scopedSlots: { customRender: 'clusterCode' }
        },
        {
          title: this.$t('chan-pin-ji-qun-ming-cheng'),
          key: 'clusterDesc',
          scopedSlots: { customRender: 'clusterDesc' }
        },
        {
          title: this.$t('chan-pin-lei-xing'),
          dataIndex: 'product'
        },
        {
          title: this.$t('ban-ben-hao'),
          key: 'productVersion',
          scopedSlots: { customRender: 'productVersion' }
        },
        {
          title: this.$t('api-tiao-yong-di-zhi'),
          key: 'apiAddr',
          scopedSlots: { customRender: 'apiAddr' }
        },
        {
          title: this.$t('cao-zuo'),
          key: 'actions',
          scopedSlots: { customRender: 'actions' }
        }
      ],
      dataList: [],
      productTypes: []
    };
  },
  computed: {
    ...mapState(['myAuth', 'productClusterList'])
  },
  mounted() {
    // this.getProductList();
    this.listProductTypes();
  },
  methods: {
    handleRefresh() {
      // this.getProductList();
      this.$store.dispatch('getProductList');
    },
    handleClickAddBtn() {
      this.showAddProductModal = true;
    },
    getProductList() {
      this.loading = true;
      listProductCluster().then((res) => {
        if (res.data.success) {
          this.dataList = res.data.data;
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    listProductTypes() {
      listProductTypes().then((res) => {
        if (res.data.success) {
          this.productTypes = res.data.data;
        }
      });
    },
    handleCancel() {
      this.showAddProductModal = false;
      this.showEditProductVersion = false;
      this.showEditApiAddr = false;
    },
    handleActions(key, record) {
      switch (key) {
        case 'delete':
          Modal.confirm({
            title: this.$t('shan-chu-chan-pin-ji-qun-que-ren'),
            content: this.$t('que-ren-yao-shan-chu-gai-chan-pin-ji-qun-ma'),
            onOk: () => {
              deleteProductCluster({ id: record.id }).then((res) => {
                if (res.data.success) {
                  this.$Message.success(this.$t('shan-chu-cheng-gong'));
                  // this.handleRefresh();
                  const selectCcProductCluster = localStorage.getItem('selectCcProductCluster');
                  if (record.clusterCode === selectCcProductCluster) {
                    localStorage.removeItem('selectCcProductCluster');
                  }
                  window.location.reload();
                }
              });
            }
          });
          break;
        default:
      }
    },
    handleEditProductVersion(record) {
      this.selectedProductCluster = cloneDeep(record);
      this.currentProductVersion = this.selectedProductCluster.productVersion;
      this.showEditProductVersion = true;
    },
    handleUpdateProductVersion() {
      updateProductVersion({ id: this.selectedProductCluster.id, productVersion: this.currentProductVersion }).then((res) => {
        if (res.data.success) {
          this.showEditProductVersion = false;
          this.$Message.success(this.$t('xiu-gai-chan-pin-ji-qun-ban-ben-hao-cheng-gong'));
          this.handleRefresh();
        }
      });
    },
    handleEditApiAddr(record) {
      this.selectedProductCluster = cloneDeep(record);
      this.currentApiAddr = this.selectedProductCluster.apiAddr;
      this.showEditApiAddr = true;
    },
    handleUpdateApiAddr() {
      updateApiAddr({ id: this.selectedProductCluster.id, apiAddr: this.currentApiAddr }).then((res) => {
        if (res.data.success) {
          this.showEditApiAddr = false;
          this.$Message.success(this.$t('xiu-gai-chan-pin-ji-qun-fang-wen-di-zhi-cheng-gong'));
          this.handleRefresh();
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.query-header {
  margin-bottom: 12px;

  .op {
    margin-left: auto;
    & > button + button {
      margin-left: 10px;
    }
  }
}
</style>
