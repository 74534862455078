<template>
  <div class="env">
    <query-header :handle-click-add-btn="handleShowAddEnv" :handle-query="handleRefresh" :handle-refresh="handleRefresh"
                  :query="queryForm" :show-add-btn="myAuth.includes('RDP_ENV_MANAGE')" :loading="loading"
                  :showSelect="false" :add-btn-text="$t('chuang-jian-huan-jing')" :placeholder="$t('qing-shu-ru-huan-jing-ming-cheng-cha-xun')"/>
    <a-table :columns="envColumns" :data-source="envData" size="small" :loading="loading">
      <div slot="envName" slot-scope="row">
        <a-button v-if="myAuth.includes('RDP_ENV_CONFIG')" size="small" type="link" @click="handleEdit(row)">
          {{ $t('bian-ji') }}
        </a-button>
        <a-popconfirm :cancel-text="$t('qu-xiao')" :ok-text="$t('que-ren')"
                      :title="$t('que-ding-shan-chu-gai-huan-jing-ma')"
                      @confirm="handleDeleteEnv(row)" v-if="myAuth.includes('RDP_ENV_MANAGE')">
          <a-button type="link">{{ $t('shan-chu') }}</a-button>
        </a-popconfirm>
      </div>
    </a-table>
    <a-modal
      :title="isEdit?$t('bian-ji-huan-jing'):$t('chuang-jian-huan-jing')"
      :visible="showAddEnv"
      @cancel="handleCancel"
    >
      <div>
        <a-form-model :model="addEnv" ref="addEnv" :rules="rules" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }"
                      label-position="right">
          <a-form-model-item :label="$t('huan-jing-ming-cheng')" prop="envName">
            <a-input v-model="addEnv.envName"/>
          </a-form-model-item>
          <a-form-model-item :label="$t('bei-zhu')">
            <a-input v-model="addEnv.description"/>
          </a-form-model-item>
        </a-form-model>
        <div class="footer">
          <a-button type="primary" @click="handleAddEnv">{{ isEdit ? $t('que-ren') : $t('tian-jia') }}</a-button>
          <a-button type="default" @click="handleCancel">{{ $t('qu-xiao') }}</a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import QueryHeader from '@/views/system/components/QueryHeader';
import { mapState } from 'vuex';

export default {
  name: 'Env',
  components: { QueryHeader },
  computed: {
    ...mapState(['userInfo', 'myCatLog', 'myAuth'])
  },
  data() {
    return {
      showAddEnv: false,
      isEdit: false,
      showDeleteEnv: false,
      loading: false,
      queryForm: { text: '' },
      selectedEnv: {},
      pageData: {
        size: 20,
        page: 1,
        total: 0
      },
      rules: {
        envName: [
          {
            required: true,
            message: this.$t('qing-tian-xie-huan-jing-ming-cheng'),
            trigger: 'blur'
          }
        ]
      },
      addEnv: {
        description: '',
        envName: ''
      },
      envColumns: [
        {
          title: this.$t('huan-jing-ming-cheng'),
          dataIndex: 'envName'
        }, {
          title: this.$t('bei-zhu'),
          dataIndex: 'description'
        }, {
          title: this.$t('cao-zuo'),
          fixed: 'right',
          width: 300,
          slots: { title: 'envName' },
          scopedSlots: { customRender: 'envName' }
        }],
      envData: [],
      showEnvData: [],
      envValidate: {
        envName: [
          {
            required: true,
            message: this.$t('yun-hang-huan-jing-ming-cheng-bu-neng-wei-kong'),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  mounted() {
    this.handleSearch();
  },
  methods: {
    async listEnv() {
      this.loading = true;
      const data = {
        envName: this.queryForm.text
      };
      const res = await this.$services.listDsEnv({ data });
      if (res.success) {
        this.envData = res.data;
        this.pageData.total = this.envData.length;
        this.handleShowData();
      }
      this.loading = false;
    },
    handleSearch() {
      this.listEnv();
    },
    handleShowAddEnv() {
      this.addEnv = {
        description: '',
        envName: ''
      };
      this.isEdit = false;
      this.showAddEnv = true;
    },
    handleRefresh() {
      this.handleSearch();
    },
    async addEnvFunc() {
      const data = {
        authKinds: [],
        description: this.addEnv.description,
        envName: this.addEnv.envName,
        queryLimit: this.addEnv.queryLimit
      };
      const res = await this.$services.addDsEnv({ data });
      if (res.success) {
        this.handleSearch();
        this.$Message.success(this.$t('tian-jia-cheng-gong'));
      }
    },
    async editEnvFunc() {
      const data = {
        authKinds: [],
        description: this.addEnv.description,
        envName: this.addEnv.envName,
        queryLimit: this.addEnv.queryLimit,
        dsEnvId: this.addEnv.id
      };
      const res = await this.$services.updateEnv({ data });
      if (res.success) {
        this.$Message.success(this.$t('bian-ji-cheng-gong'));
        this.handleSearch();
      }
    },
    handleAddEnv() {
      this.$refs.addEnv.validate().then(() => {
        if (this.isEdit) {
          this.editEnvFunc();
        } else {
          this.addEnvFunc();
        }
        this.showAddEnv = false;
      });
    },
    async deleteEnvFunc() {
      const data = {
        dsEnvId: this.selectedEnv.id
      };
      const res = await this.$services.deleteEnv({ data });
      if (res.success) {
        this.$Message.success(this.$t('shan-chu-cheng-gong'));
        this.pageData.page = 1;
        this.handleSearch();
      }
    },
    handleDeleteEnv(row) {
      this.selectedEnv = row;
      this.deleteEnvFunc();
      this.showDeleteEnv = false;
    },
    handleCancel() {
      this.showAddEnv = false;
      this.showDeleteEnv = false;
    },
    handleShowData() {
      this.showEnvData = this.envData.slice((this.pageData.page - 1) * this.pageData.size, this.pageData.page * this.pageData.size);
    },
    handlePageChange(page) {
      this.pageData.page = page;
      this.handleShowData();
    },
    handlePageSizeChange(size) {
      this.pageData.size = size;
      this.pageData.page = 1;
      this.handleShowData();
    },
    handleEdit(row) {
      this.isEdit = true;
      this.addEnv = {
        id: row.id,
        description: row.description,
        envName: row.envName
      };
      this.showAddEnv = true;
    }
  }
};
</script>

<style lang="less" scoped>
.env {
  height: 100%;
  position: relative;

  .operation {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;

    .left, .right {
      display: flex;
      align-items: center;
    }

    .left {
      .type-text {
        word-break: keep-all;
        margin-right: 10px;
      }

      .type {
        margin-right: 20px;
      }

      .text {
        margin-right: 10px;
      }
    }

    .right {
      .refresh {
        margin-left: 10px;
      }
    }
  }
}

.add-env-auth-title {
  color: #333333;
  font-family: PingFangSC-Semibold;
  font-weight: 500;
  margin-bottom: 18px;

  .add-env-auth-remark {
    color: @icon-color;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    margin-left: 8px;
  }
}

.add-env-item {
  line-height: 50px;
}

.page-container {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
}
</style>
